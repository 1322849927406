<div class="accounts">
    <div class="row">
        <div class="col text-right p-3">
            <button class="btn btn-primary" type="button" (click)="add()">
                <i class="fa fa-plus"></i> Nova Conta
            </button>
        </div>
    </div>
    <div class="accounts-table">
        <p-table
            [columns]="columns"
            [value]="accounts"
            [rows]="50"
            [virtualScroll]="true"
            [virtualRowHeight]="34"
            [scrollable]="true"
            scrollHeight="75vh"
        >
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns">
                        {{ col.header }}
                    </th>
                    <th class="accounts-table-action p-0"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr class="accounts-table-row" (click)="open(rowData)">
                    <td *ngFor="let col of columns">
                        <span>{{
                            getCustomValues(col.field, rowData[col.field])
                        }}</span>
                    </td>
                    <td
                        class="heading-view text-center accounts-table-action p-0"
                    >
                        <button
                            type="button"
                            class="btn btn-sm"
                            (click)="openEmailModal(rowData)"
                            tooltipPosition="top"
                            pTooltip="Enviar e-mail de primeiro acesso ou recuperação de senha."
                        >
                            <i class="fa fa-paper-plane"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <p-dialog
            header="Conta"
            [(visible)]="display"
            styleClass="accounts-dialog"
            modal="true"
        >
            <dynamic-forms
                #dynamicForm
                [form]="form"
                [(model)]="account"
                [fieldsConfig]="fieldsConfig"
                [(errorMessage)]="errorMessage"
                (onSubmitted)="onSubmitted($event)"
                (onCancelled)="onCancelled($event)"
            >
            </dynamic-forms>
        </p-dialog>

        <p-dialog
            header="Enviar E-mail"
            [(visible)]="showEmailModal"
            modal="true"
        >
            <div class="container text-center">
                <div class="row">
                    <div class="col">
                        <span
                            >Qual e-mail você deseja enviar para
                            <b>{{ account.Email }}?</b></span
                        >
                    </div>
                </div>
                <div class="row">
                    <div
                        *ngIf="account.Role == 'Company'"
                        class="col-sm-6 col-md p-1"
                    >
                        <button
                            class="btn btn-sm btn-outline-primary btn-block button-email"
                            (click)="confirmCompanyEmailSend()"
                        >
                            Senha de Primeiro Acesso
                        </button>
                    </div>
                    <div class="col-sm-6 col-md p-1">
                        <button
                            class="btn btn-sm btn-outline-primary btn-block button-email"
                            (click)="confirmAccountEmailSend()"
                        >
                            Alteração de Senha
                        </button>
                    </div>
                </div>
            </div>
        </p-dialog>
    </div>
</div>
