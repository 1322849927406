import { environment } from 'src/environments/environment';

export class ServiceConfig {
    public static readonly API_ENDPOINT = environment.api.API_ENDPOINT;
    public static readonly LOGIN_ENDPOINT = environment.api.LOGIN_ENDPOINT;
    public static readonly SOCKET_IO_ENDPOINT =
        environment.api.SOCKET_IO_ENDPOINT;

    public static readonly STATIC_CONTENT_ENDPOINT =
        environment.storage.STATIC_CONTENT_ENDPOINT;
    public static readonly PRODUCT_IMAGE_ENDPOINT =
        environment.storage.PRODUCT_IMAGE_ENDPOINT;

    public static readonly HELP_GUIDE_URL = environment.misc.HELP_GUIDE_URL;
    public static readonly URL_SITE = environment.misc.URL_SITE;
    public static readonly RECAPTCHA_KEY = environment.misc.RECAPTCHA_KEY;
    public static readonly WHATSAPP_NUMBER = environment.misc.WHATSAPP_NUMBER;
}
