<div class="companies">
    <div class="row text-nowrap">
        <div class="col">
            <div class="p-2 text-right">
                <button
                    type="button"
                    class="btn btn-md btn-warning"
                    (click)="forceCheckNewOrders()"
                >
                    <i class="fa fa-play pr-2"></i>
                    Checar Novos Pedidos
                </button>

                <button
                    type="button"
                    class="btn btn-md btn-primary ml-2"
                    (click)="add()"
                >
                    <i class="fa fa-plus pr-2"></i>
                    Nova Empresa
                </button>
            </div>
        </div>
    </div>

    <div>
        <p-table
            [columns]="columns"
            [value]="companies"
            [rows]="50"
            [virtualScroll]="true"
            [virtualRowHeight]="34"
            [scrollable]="true"
            scrollHeight="75vh"
        >
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let column of columns">
                        {{ column.header }}
                    </th>
                    <th class="heading-view">Ver</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr
                    style="height: 34px; cursor: pointer"
                    (click)="open(rowData)"
                >
                    <td *ngFor="let col of columns">
                        <span>{{
                            getCustomValues(col.field, rowData[col.field])
                        }}</span>
                    </td>
                    <td class="heading-view text-center">
                        <button
                            type="button"
                            class="btn btn-sm"
                            (click)="changeCompany(rowData)"
                        >
                            <i class="fa fa-eye"></i>
                        </button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <p-dialog
        header="Empresa"
        [(visible)]="display"
        styleClass="companies-dialog"
        modal="true"
        baseZIndex="1030"
    >
        <dynamic-forms
            #dynamicForm
            [form]="form"
            [(model)]="company"
            [tabsConfig]="tabsConfig"
            (onCancelled)="onCancelled($event)"
            (onSubmitted)="onSubmitted($event)"
        >
        </dynamic-forms>
    </p-dialog>

    <p-dialog
        header="Nova Empresa"
        [(visible)]="displayAddCompany"
        styleClass="companies-add"
        modal="true"
        autoZIndex="true"
    >
        <div class="container">
            <div>
                <label>Selecione a conta que a nova empresa pertence:</label>
            </div>
            <div>
                <p-dropdown
                    styleClass="companies-add-dd"
                    [options]="accountList"
                    [filter]="true"
                    [virtualScroll]="true"
                    itemSize="30"
                    filterBy="label,value.name"
                    showClear="true"
                    appendTo="body"
                    placeholder="Selecione uma conta..."
                    required="true"
                    [(ngModel)]="selectedAccountId"
                >
                </p-dropdown>
            </div>
        </div>
        <p-footer>
            <button
                type="button"
                class="btn btn-primary"
                [disabled]="!selectedAccountId"
                (click)="confirmNewCompany()"
            >
                Criar Empresa
            </button>
        </p-footer>
    </p-dialog>
</div>
