export const environment = {
    production: false,
    firebase: {
        apiKey: 'AIzaSyAV1XqZ126ZCsNOIr15YGzS9Echwwjj_n0',
        authDomain: 'foodra-92aac.firebaseapp.com',
        databaseURL: 'https://foodra-92aac.firebaseio.com',
        projectId: 'foodra-92aac',
        storageBucket: 'foodra-92aac.appspot.com',
        messagingSenderId: '326312613833',
        appId: '1:326312613833:web:1ce978f6f683cb62',
    },
    api: {
        API_ENDPOINT: 'https://api-dev.foodra.app',
        LOGIN_ENDPOINT: 'https://api-dev.foodra.app/Auth',
        SOCKET_IO_ENDPOINT: 'https://api-dev.foodra.app:5010',
        CEP: 'https://viacep.com.br/ws/{cep}/json/',
    },
    storage: {
        STATIC_CONTENT_ENDPOINT:
            'https://fooderia-dev.s3-sa-east-1.amazonaws.com/{company}/{content}',
        PRODUCT_IMAGE_ENDPOINT:
            'https://fooderia-dev.s3-sa-east-1.amazonaws.com/{company}/product/{image}',
    },
    misc: {
        HELP_GUIDE_URL:
            'https://pitch.com/embed/4fd1cd90-b2c8-41bd-9a6d-35dc1acd83b3',
        URL_SITE: 'https://admin-dev.foodra.app',
        RECAPTCHA_KEY: '6Le_Y4YUAAAAAM3B5mTNn8X1trDzVmLmXdf7JYDb',
        WHATSAPP_NUMBER: '5511986452628',
    },
};
