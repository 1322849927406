import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
    DialogModule,
    SharedModule,
    TableModule,
    ToastModule,
    TooltipModule,
    VirtualScrollerModule,
} from 'primeng';
import { AccountService } from 'src/providers/account.service';
import { DynamicFormsModule } from 'src/ui-components/dynamic-forms/dynamic-forms.module';
import { AccountsComponent } from './accounts/accounts.component';

const imports = [
    BrowserModule,
    DynamicFormsModule,
    TableModule,
    DialogModule,
    VirtualScrollerModule,
    SharedModule,
    ToastModule,
    TooltipModule,
];

const services = [AccountService];

@NgModule({
    imports: [imports],
    exports: [AccountsComponent],
    declarations: [AccountsComponent],
    providers: [services],
})
export class AccountsModule {}
